import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '@environment/environment';
import { getAuth, provideAuth } from '@angular/fire/auth';
// import { provideStorage, getStorage } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { LoggerModule } from 'ngx-logger';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireModule } from '@angular/fire/compat';
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseKeys)),
    provideAuth(() => getAuth()),
    // provideStorage(() => getStorage()),
    // AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebaseKeys),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    LoggerModule.forRoot({ level: environment.loggerLevel }),
    NgApexchartsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA] // Agrega esta línea
})
export class AppModule { }
