export const companies = [
    { value: 'tpsa', viewValue: 'Oechsle', tenantId: 'TPSA' },
    { value: 'hpsa', viewValue: 'Promart Perú', tenantId: 'HPSA' },
    { value: 'spsa', viewValue: 'Plaza Vea', tenantId: 'PVEA' },
    { value: 'rp', viewValue: 'Real Plaza', tenantId:'RPLAZA' },
    { value: 'hesa', viewValue: 'Promart Ecuador', tenantId: 'HPSAEC' },
];

export const orderByFilter = [
  { value: 'storeCode-ASC', viewValue: 'Código de tienda - Asc.' },
  { value: 'storeCode-DESC', viewValue: 'Código de tienda - Desc' },
  { value: 'storeName-ASC', viewValue: 'Nombre de tienda - Asc.' },
  { value: 'storeName-DESC', viewValue: 'Nombre de tienda - Desc.' },
  { value: 'createAt-ASC', viewValue: 'Fecha de creación - Asc.' },
  { value: 'createAt-DESC', viewValue: 'Fecha de creación - Desc.' },
  { value: 'isActive-ASC', viewValue: 'Estado - Asc.' },
  { value: 'isActive-DESC', viewValue: 'Estado - Desc.' }
];

export const companiesWithCategory = [
    'hpsa',
    'spsa'
];

export const typeSearchWithTimeField = [
    'timeAttention',
    'usageRt'
]

export const typeSearchUsedRTField = [
    'searchSalesNote'
]

export const limitPerPage = 10;

export enum CustomerSearchType {
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
  EMAIL = 'EMAIL',
  ORDER_ID = 'ORDER_ID',
  SALE_NOTE_NUMBER = 'SALE_NOTE_NUMBER',
  REQUEST_NUMBER = 'REQUEST_NUMBER'
}

export enum OrderByEnum {
  SELLTENANTID = "sellTenantId",
  DISPATCHTENANTID = "dispatchTenantId",
  REQUESTREASONID = "requestReasonId",
  CUSTOMERDOCUMENT = "customerDocument",
  CREATEDAT = "createdAt",
  TIMESTARTEDAT = "timeStartedAt"
}

export enum SortOrderEnum {
  DESC = "DESC",
  ASC = "ASC",
}

export const regexTypeData : Map<string, RegExp> = new Map([
  ['email', new RegExp(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z]{2,3}?$/)],
  ['id', new RegExp(/^[A-Za-z0-9]{8,12}$/)],//dni|pasaporte|ruc(11)-carnet de extranjeria(12)
  ['request', new RegExp(/^(PVEA|VIV|RPLAZA|HPSA|TPSA|AGORA|MKR|AGORA_SHOP|JOKR|SHPSTAR)-(CD|ST)-[A-Za-z0-9]{8,12}-[0-9]+$/)],
  ['order', new RegExp(/^((\d{13}-\d{2})|(\d{15})|(v\d+viv-\d{2})|(v\d+plzv-\d{2}|(v\d+pvqa-\d{2})|(v\d+vivqa-\d{2})))?$/)],
  ['sale-note', new RegExp(/^\d{13}?$/)]
]);

export const searchTypeMap: Map<string, CustomerSearchType> = new Map([
  ['email', CustomerSearchType.EMAIL],
  ['id', CustomerSearchType.IDENTITY_DOCUMENT],
  ['request', CustomerSearchType.REQUEST_NUMBER],
  ['order', CustomerSearchType.ORDER_ID],
  ['sale-note', CustomerSearchType.SALE_NOTE_NUMBER],
]);

export const systems = [
    { value: 'attentions', viewValue: 'Atención de pedidos' },
    { value: 'security', viewValue: 'Validación de QR - Seguridad' },
    { value: 'withdraws', viewValue: 'Retiro en tienda - Cliente' },
    { value: 'monitoring', viewValue: 'Monitoreo y gestión' },
    { value: 'queue-manager', viewValue: 'Gestor de colas' }
];

export const withdrawalValidationEnabled: any[] = [ 'hpsa', 'tpsa', 'spsa', 'hesa' ];

export const domainsAllowedInRegister: any[] = [
    '@promart.pe',
    '@promart.ec',
    '@spsa.pe',
    '@oechsle.pe',
    '@realplaza.com.pe',
    '@irdigital.pe',
    '@indigitalxp.pe',
    '@plazavea.pe',
    '@oslo.pe',
    '@tiendasmass.pe',
    '@exacta.pe'
];

export const imagesWithdrawalDetail: any[] =  [
    {id: 0, name: 'imagensustento1', description: '' },
    {id: 1, name: 'imagensustento2', description: '' },
    {id: 2, name: 'imagensustento3', description: '' },
    {id: 3, name: 'imagensustento4', description: '' },
    {id: 4, name: 'imagensustento5', description: '' },
    {id: 5, name: 'imagensustento6', description: '' }
];

export const sale = [
    { value: 'Venta por Internet', viewValue: 'Ecommerce' },
    { value: 'Venta Empresa', viewValue: 'Venta empresa' },
    { value: 'Venta Directa', viewValue: 'Venta retail' }
];

export const saleMonitoring = [
    { value: 'Venta por Internet', viewValue: 'Venta por Internet' },
    { value: 'Venta Empresa', viewValue: 'Venta Empresa' },
    { value: 'Venta Directa', viewValue: 'Venta Directa' }
];

export const categories = [
    { value: 'FOOD', viewValue: 'Food' },
    { value: 'NONFOOD', viewValue: 'Non Food' }
];

export const categoriesHpsa = [
    { value: 'DIM', viewValue: 'Dimensionado' },
    { value: 'NODIM', viewValue: 'No Dimensionado' }
];

export enum Service {
    RETURN = 1,
    TECHNICAL_SERVICE,
    OTHERS,
    WITHDRAW,
    WITHDRAW_CAR
}

export const versionApp = 'v1.0.0';

export const commons = {
    companies,
    regexTypeData: regexTypeData,
    searchTypeMap: searchTypeMap,
    companiesWithCategory,
    typeSearchWithTimeField,
    typeSearchUsedRTField,
    systems,
    service: Service,
    domainsAllowedInRegister,
    versionApp,
    imagesWithdrawalDetail,
    withdrawalValidationEnabled,
    sale,
    saleMonitoring,
    categories,
    categoriesHpsa,
    limitPerPage
}

