import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {

    this.matIconRegistry.addSvgIcon(
      "rexRequestNumber",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-request-number.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexDNISearch",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-dni-search.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexOrderId",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-order-id.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexSaleNote",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-sale-note.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexEmail",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-email.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "formSearchError",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-search-error.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexSearchError",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/search-2.svg"),
    );
  }
}
